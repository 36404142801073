<template>
  
    <div id="Solicitudes">
       
      
  
        <div v-if="historyOption =='Default'">

               <div class="text_option txt1">
                           
                            <h3>Identificación de Muestras</h3>
                    </div>

            <div class="dflx">
        
        
         <div class="btnfiltro btn1 dflx" @click="filtFecha()"> <div class="txtb"> FECHA</div> <img src="../../assets/filtrar.png" alt="icono filtrar"></div>
         <div class="btnfiltro btn2 dflx" @click="filtActivas()"> <div class="txtb">ACTIVAS</div> <img src="../../assets/filtrar2.png" alt="icono filtrar"></div>
         <div class="btnfiltro btn2 dflx" @click="filtConcluidas()"> <div class="txtb">CONCLUIDAS</div> <img src="../../assets/filtrar2.png" alt="icono filtrar"></div>
        
      
        
     
          <div class="buscador">
            <input v-model="search_value" type="search" id="input-search" class="form-control" :placeholder="'Búsqueda'"  />
            <div class="icon_buscar">
                <img @click="search(search_value)" src="../../assets/Search.png" alt="icono buscar">
            </div>

        </div>

          <div v-if="historyOption =='Default'" id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev" id="btn-prev" class="btn-navigate  ">	&#10094;</button>
                        <p class="num-pages">{{currentPage}} - {{totalPages}}</p>
                        <button @click="next"  id="btn-next" class="btn-navigate  ">&#10095;</button>
                     </div>
        </div>


        </div>
        

        <div class="tbl_solserv">
            <div class="tbl_header">
                
                 <div class="tblh hp4">
                   <p>FECHA</p>
                </div>
                <div class="tblh hp1">
                  <p>CLAVE</p>
                </div>
               
                <div class="tblh hp3">
                  <p>HORA</p>
                </div>

                <div class="tblh hp5">
                  <p>NO.  <br>MUESTRAS</p>
                </div>

                 <div class="tblh hp3">
                  <p>ESTATUS</p>
                </div>
               
           <!--        <div class="tblh hp2">
                    <p>OBSERVACIONES</p>
                </div> -->
                 
                
            </div>
          
            <div tbl_content>
                <div v-if="data != 'No se encontraron coincidencias.' && data != 'error'">
                    <div class="tbl_items" v-for="(item, key) in filteredItems" :key="key">
                       
                        <div class="tbl_item itp4">
                               <p @click="changeView('Muestras_Solicitud', item)">{{convertdate(item.fecha)}}</p>
                        </div>
                         <div  class="tbl_item itp1">
                               <p @click="changeView('Muestras_Solicitud', item)">{{item.clave_LAASON}}</p>
                        </div>
                       
                       
                       
                         <div class="tbl_item itp3">
                            <p @click="changeView('Muestras_Solicitud', item)">{{item.hr}}</p>
                        </div>
                      
                      <div  class="tbl_item itp1">
                               <p @click="changeView('Muestras_Solicitud', item)">{{item.len}}</p>
                        </div>

                           <div class="tbl_item itp3">
                            <p @click="changeView('Muestras_Solicitud', item)">{{item.estatus}}</p>
                        </div>

                      <!--     <div  class="tbl_item itp2">
                            <p @click="changeView('Muestras_Solicitud', item)"> <span v-if="item.obv != null &&  item.obv != ''">{{item.obv}}</span> <span v-else>Sin observaciones...</span></p>
                        </div> -->
                    </div>
                </div>  
                <div v-else>
                    <p class="snr">Aun no hay registros.</p>
                </div>
            </div>
        </div>

        </div>
        <div v-if="historyOption == 'Muestras_Solicitud'">
          <SolicitudMuestras/>

        </div>
        <div v-if="historyOption == 'MuestraDetalle'">
         <MuestraDetalle/>

        </div>

      
       


       
     

      
        

       
        

    </div>
</template>
<script>

import SolicitudMuestras from '../servicios/SolicitudMuestras.vue'
import MuestraDetalle from '../servicios/muestrasDetalle.vue';


import dayjs from 'dayjs'
import {mapActions} from 'vuex';
export default {
    name: 'Solicitudes',
    components:{
       SolicitudMuestras,
       MuestraDetalle,
    },
 
   data(){
       return{

           status:'',
           message:'',
           item: '',
           search_value:'',
           currentPage: 1,
           itemsPerPage:   12,


           viewModal:false,
           itemDel:''
           
       }
   },
   

     async created (){
      let response = await  this.getIdentity()
     
       let data = {
           "id": response.result.sub,
           "option": 'servicios/sucursal'
       }
       this.getInfoByIdSrv(data)
   },

    computed:{
        data() {
             return this.$store.getters["solserv/data"];
        },
        historyOption(){
            return this.$store.getters["solserv/getHistoryOption"]
        },
 identity() {
            return this.$store.getters['admin/getIdentity'];
            },
        



          //Paginacion
        allItems () {
            return Object.keys(this.data).map(pid => this.data[pid])
        },
        filteredItems () {
        
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage =1
                }
        return total
       },
    },
   
   methods:{
         ...mapActions('solserv', ['setHistoryOptionSrv']),
         ...mapActions('solserv', ['setAddedSrv']),
         ...mapActions('solserv', ['getInfoByIdSrv']),
         
       
       
       
       ...mapActions('main', ['setHistoryOptionMain']),
      ...mapActions('solserv', ['setHistoryOptionSrv']),
       ...mapActions('solserv', ['setHistoryOptionSrv']),


       filtActivas: async  function(){
           let response = await  this.getIdentity()
     
            let data = {
                "id": response.result.sub,
                "option": 'servicios/sucursal'
            }
            this.getInfoByIdSrv(data)
        },

         filtConcluidas: async  function(){
           let response = await  this.getIdentity()
            
            let data = {
                "id": response.result.sub,
                "option": 'servicios/sucursal/concluidas'
            }
            this.getInfoByIdSrv(data)
        },  

         filtFecha: async  function(){
           let response = await  this.getIdentity()
     
            let data = {
                "id": response.result.sub,
                "option": 'servicios/sucursal/fecha'
            }
            this.getInfoByIdSrv(data)
        },

         
  getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
      },


         
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
            setTimeout(() => this.wait()
            ,500);
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },
         eliminar: async function(){
             let id = this.itemDel
           this.status='';
           this.message='';

             let result = await this.$store.dispatch("solserv/deleteItemSrv", {option: 'servicios', id:id});
          
            if(result.status=='error'){
            this.message = result.message;
            this.status='error'
            this.delStatus();
            }else{
                this.status='';
                this.message='';
                this.wait()
            }
         },
         search: async function(value){
            let option = 'suc/servicios';
            let response = await  this.getIdentity()
          
            let payload ={
              option : option,
              search: value,
             id_client: response.result.sub,
            }
            if(value!=""){
                this.$store.dispatch("solserv/searchSrvCli", payload);
            }else{
              
     
                let data = {
                    "id": response.result.sub,
                    "option": 'servicios/sucursal'
                }
                this.getInfoByIdSrv(data)
            }
         },
         changeView(option, item){
             this.item=item
             this.setAddedSrv(item)
             this.setHistoryOptionSrv(option)

          
         },



         // botones de paginacion.
         next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
          }
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
          }
        },



          convertdate(fecha){
            let day = dayjs(fecha).format('DD/MM/YYYY')
            return day
        },


   }

    
 
}
</script>

<style scoped>

.text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
    margin-bottom: 2.083333333333333VW;
  }
  

p.snr{
         width:  54.3VW;
          height: 2.2916666666666665VW;
          text-align: center;
          padding-top: .5vw;
        background: #F8F8F8;
        color: #868686;
             font-size: 0.7375VW;
    }



.arrow_left{
    position: absolute;
    width: 2.5VW;
    height: 2.5VW;
    right: 68vw;
    top: 11vw;
    padding-top: .7vw;
    padding-left: .23vw;
  
    border-radius:  50% ;
    transition: all 500ms;
   

}.arrow_left:hover{
       background: #D3D3D3;
}
    #Solicitudes{
        margin-top: 5.288020833333333VW;
        margin-left: 9.427083333333334VW;
    }

    .buscador {
    display: flex;
    margin-left: 7.5375VW;
    }
   .buscador input{
     border-radius: 0vw;
     border: 0.052083333333333336VW solid var(--color-6);
     width: 11.250000000000002VW;
     height: 1.8192708333333332VW; 
     padding-left: 1.8vw;
     font-size: 0.6770833333333334VW;
     color: var(--color-4) !important;
     border-right: 0vw;
   }.buscador input:target{
       background: var(--color-1);
   }

   input:-internal-autofill-selected {
       background: white !important;
   }

   .icon_buscar{
        border-radius: 0vw;
        border: 0.052083333333333336VW solid var(--color-6);
        width: 2.5VW;
        height: 1.8192708333333332VW;
        padding-left: 0.8vw;
        font-size: 0.6770833333333334VW;
        color: var(--color-4) !important;
    border-left: 0vw;
   }.icon_buscar img{
       width: 0.9895833333333333VW;
       height: 0.9895833333333333VW;
       margin-top: 0.4411458333333334VW;
   }




    .btn_añadir button{
        background: var(--color-1);
        border: 0vw;
        width: 17.395833333333332VW;
        height: 2.2916666666666665VW;
        color: var(--color-5);
        font-size: 0.9375VW;
        font-weight: 300;
        text-align: left;
         padding-left: 0.78125VW;
        
    }.btn_añadir button span{
        font-weight: 700;
        padding-left: 0.5744791666666667VW;
    }

    .tbl_solserv{
        width: 29VW;
        margin-top: 1.5625VW;
    }

    .tbl_header{
        display: flex;
        background:var(--color-2);
    }.tblh, .tbl_item{
        height: 2.2916666666666665VW;
    }.tblh p{
        color:var(--color-5);
        font-weight: 700;
        font-size:0.625VW;
        padding-top: 0.66875VW;
        /*padding-left: 1.4583333333333333VW;*/
        text-align: center;
    }.hp2{
        width: 25.521vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hp4{
        width: 5.15625VW;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hp3{
        width: 6.771vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hp1{
        width: 5.156vw;
        
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hp1 p{
        font-size: 0.625vw;
        padding-top: 0.66875VW;
        
    }

    .hp5{
        width: 5.156vw;
        
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hp5 p{
        font-size: 0.625vw;
        padding-top: 0.26875VW;
        
    }
    
    .hoptions{
        width: 5.156vw;
    }

    .tbl_items{
        display: flex;
        
    }.tbl_item{
         padding-top: 0.36875VW;
       
         background: #F8F8F8;
         border: 1px solid white;

    }.tbl_item p{
        font-size:0.7291666666666666VW;
        color: #868686;
      
    }.itp2{
         width: 25.521vw;
         overflow: auto;
          color: var(--color-4);
          cursor: pointer;
    }.itp2 p{
        color: var(--color-4);
        font-style: italic;
        font-weight: 700;
         cursor: pointer;
          padding-left: 1VW;
    }.itp4{
        width: 5.15625VW;
        text-align: center;
         cursor: pointer;
         overflow: auto;
    }.itp3{
        width: 6.771vw;
          text-align: center;
         overflow: auto;
          cursor: pointer;
    }.itp1{
        width: 5.156vw;
        overflow: auto;
        cursor: pointer;
    }
    .itp1{
        padding-left: 0vw !important;
    }.itp1 p{
         color: var(--color-4);
         font-weight: 600;
         text-align: center;
         
    }
    .itoptions{
            justify-content: center;
         width: 5.15625VW;
       background: #F8F8F8;
        display: flex;
       padding-top: 0.26875VW;
       
        border: 1px solid white;
    }
    .icon_eliminar{
        background: var(--color-4);
        width: 1.6145833333333335VW;
        height: 1.5625VW;
        border-radius: 3vw;
    }.icon_eliminar:hover{
        background: var(--color-6);
    }.icon_eliminar img{
        width: 0.625VW;
        height: 0.625VW;
        margin-top: 0.3166666666666667VW;
        margin-left:0.46875VW;
    }



    .btnfiltro{
        cursor:pointer;
        width: 7.240vw;
        height: 1.823vw;
        border: 0px;
        margin-right: 0.072vw;
        font-size: 0.625vw;
        font-weight: 700;
        color:white;
        text-align: left;
        padding-top: .4vw;
        padding-left: 0.990vw;
    }
   .btnfiltro .txtb{
        width: 4.6vw;
       
    }
    .btnfiltro img{
        width: 0.885vw;
        height: 0.938vw;
    }
    .btn1{
        background: #009084;
    }
    .btn2{
         background: #007E94;
    }

     

    

  /** estilos scroll bar */
    .tbl_item::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .tbl_item::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-button:increment,.tbl_item::-webkit-scrollbar-button {
        display: none;
    } 

    .tbl_item::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-thumb {
        background-color: #797979;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .tbl_item::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }

</style>
